import React from "react"
import Swipe from 'react-easy-swipe';

import Layout from "../layouts/Layout"
/*import '../../node_modules/font-awesome/css/font-awesome.min.css';
import '../shared/css/typography.css';
import '../shared/css/ui-elements.css';
import '../sxhared/css/styles.css';
import '../shared/css/page.css';*/
import css from './gallery.module.css';
import cn from 'classnames';
/*import Img from "gatsby-image"*/
import { graphql } from "gatsby"
import { Expo, TweenMax } from "gsap/TweenMax"
import { ScrollToPlugin } from "gsap/all"
import { getGraphQLPath, getGraphQLPathGallery } from "../shared/config"
import SEO from "../core/seo"

/*const styles = {
  background: `url("../static/home/training-sky.jpg") no-repeat center center`,
  backgroundSize: 'cover'
}*/
/*

const images = [
  { big: '../static/home/training-sky.jpg', thumb: '../static/home/training-sky.jpg'},
  { big: '../static/home/profilo1.jpg', thumb: '../static/home/profilo1.jpg'},
  { big: '../static/home/profilo2.jpg', thumb: '../static/home/profilo2.jpg'}
]
*/

class Gallery extends React.Component {
  state = {index: null, bigUrl: null, isLoading: false}

  componentDidMount() {
    this.showImage(0);
    console.log(process.env.DEVELOPMENT)
    const a = ScrollToPlugin;
  }

  showImage(index) {
    const imagePath = this.props.data.allFile.edges[index].node.childImageSharp.fluid.src;
    console.log(imagePath)
    const bigUrl = getGraphQLPathGallery(imagePath);
    //const bigUrl = imagePath;

    TweenMax.to(".clip-circle", 1.00, {
      delay: 0,
      webkitClipPath: `circle(0 at 50% 50%)`,
      clipPath: `circle('0 at 50% 50%)`,
      ease: Expo.easeInOut,
      onComplete: () => this.loadImage(index, bigUrl)
    });
    this.setState({ index});

    // TweenMax.to(`.${css.page_image}`, 0.3, { opacity:0, scale: 0.9, force3D:true, onComplete: () => this.loadImage(index, bigUrl)})
  }

  loadImage(index, bigUrl) {
    this.setState({ isLoading: true });

    TweenMax.killTweensOf(`.${css.blur_image}`)
    TweenMax.killTweensOf(`.${css.page_image}`)
    // Wait if Background is loadad and animate it

    TweenMax.to(`.${css.blur_image}`, 0.5, { opacity:0, scale: 1, force3D:true, onComplete: () => {

      const bgImg = new Image();
      bgImg.onload = () => {
        // show image with transition
        // show image
        //TweenMax.to(`.${css.page_image}`, 0.5, { opacity:1, scale: 0.9, force3D:true})
        this.aniMask(index, bigUrl)

        // show blur image
        TweenMax.to(`.${css.blur_image}`, 1.5, { opacity:1, scale: 0.9, force3D:true})
        this.setState({ bigUrl, isLoading: false });

      };

      bgImg.src = bigUrl;

    }})
  }

  aniMask(index, bigUrl) {
    const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    const size = w > h ? w : h;
    TweenMax.to(".clip-circle", 1.00, {
      delay: 0,
      webkitClipPath: `circle(${size/2 + 50} at 50% 50%)`,
      clipPath: `circle(${size/2 + 50} at 50% 50%)`,
      ease: Expo.easeInOut,
     // onComplete: () => this.loadImage(index, bigUrl)
    });
  }

  gotoRight = () => {
    let nextIndex;
    if (this.state.index < this.props.data.allFile.edges.length - 1) {
      nextIndex = this.state.index + 1;
    } else {
      nextIndex = 0;
    }
    // scroll to thumb
    TweenMax.to('.' + css.page_thumbs, 1, {scrollTo: `.tmb${nextIndex}`});

    this.showImage(nextIndex)
  }

  gotoLeft = () => {
    let nextIndex;
    if (this.state.index > 0) {
      nextIndex = this.state.index - 1;
    } else {
      nextIndex = this.props.data.allFile.edges.length - 1;
    }
    // scroll to thumb
    TweenMax.to('.' + css.page_thumbs, 1, {scrollTo: `.tmb${nextIndex}`});

    this.showImage(nextIndex)
  }

  render() {
    /*const bigImage = this.props.data.allFile.edges[this.state.index].node.childImageSharp.fluid.src;*/
    const styles = {
      background: `url("${this.state.bigUrl}") no-repeat center center`,
      /*position: 'absolute',
      top: 0, bottom: 0, left: 0, right: 0,
      zIndex: 2*/
    }
    const bgBlur = {
      background: `url("${this.state.bigUrl}") no-repeat center center`,
      opacity: 0.3,
      /*backgroundSize: '100% 100%',*/
    };

    const page = {
      position: 'absolute',
      top: 0, bottom: 0, left: 0, right: 0,
      height: '100vh',
      zIndex: 0,
      overflow: 'hidden'
    }

    return (
      <Layout {...this.props}>
        <SEO title="Photo Gallery"/>
        <div style={bgBlur} className={css.blur_image}>

        </div>
        <div style={page}>
          <div className={css.page_wrapper}>

            <div
              className={css.page_thumbs}
            >
              <div style={{display: 'flex', flexDirection: 'column'}}>
                {
                  this.props.data.allFile.edges.map((img, index) => {
                    const imagePath = img.node.childImageSharp.resize.src;
                    const path = getGraphQLPathGallery(imagePath);
                    // const path = imagePath;
                    return <img
                      key={'tmb' + index}
                      src={path}
                      alt={'photo' + index}
                      className={cn(css.thumb, `tmb` + index, {[css.thumb_active]: index === this.state.index})}
                      onClick={() => this.showImage(index)}
                    />
                  })
                }


              </div>
            </div>

            <Swipe
              onSwipeLeft={this.gotoRight}
              onSwipeRight={this.gotoLeft}
              tolerance={ 50 }
            >


              <div className={css.page_image_wrapper}>
                <div className={cn(css.spinner, {[css.spinner_show]: this.state.isLoading})}>
                  <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
                </div>
                <div
                  className={cn(css.page_image, 'clip-circle')}
                  style={styles}
                >
                </div>
              </div>
            </Swipe>

              <div
                className={cn('page-arrow-left', css.page_arrow_left)}
                onClick={this.gotoLeft}
              >
                <div>
                  <i className="fa fa-angle-left"></i>
                </div>
              </div>

              <div
                className="page-arrow-right"
                onClick={this.gotoRight}
              >
                <div>
                  <i className="fa fa-angle-right"></i>
                </div>
              </div>
          </div>

        </div>
      </Layout>
    )
  }
}


export default Gallery

// extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }

export const query = graphql`
  query {
    allFile(
      sort: {
        fields: [name]
      }
      filter: {
        relativeDirectory: {eq: "photo-gallery"}
      }
    ) {
      edges {
        node {
          dir,
          absolutePath,
          childImageSharp {
            fluid(maxWidth: 1920, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
            resize(width: 120, quality: 50) {
              src
            }
          }
        }
      }
    }
  }
  `
/*

export const query = graphql`
  query {
    file(relativePath: { eq: "gatsby-astronaut.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
  `
*/
/*

export const query = graphql`
  fragment squareImage on File {
    childImageSharp {
      fluid(maxWidth: 200, maxHeight: 200) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  `
*/
